import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import NotFoundPage from "./components/NotFoundPage.js";
import RankingPage from "./components/RankingPage.js";
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Layout from "./components/Layout";

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout><App/></Layout>,
    errorElement: <NotFoundPage/>,
  },
  {
    path: "/rankings",
    element: <Layout><RankingPage/></Layout>,
    errorElement: <NotFoundPage/>,
  }
]);
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
