export const pick = (list, count) => {
  if (count > list.length) {
    return list.slice();
  }
  const picked = [];
  for (let i = 0; i < count; i++) {
    const index = Math.floor(Math.random() * list.length);
    picked.push(list[index]);
    list = list.slice(0, index).concat(list.slice(index + 1));
  }
  return picked;
}

export const postData = (url = '', data = {}) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
}