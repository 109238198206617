import styles from './Layout.module.css';
import {Link} from 'react-router-dom';

export default function Layout({children}) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Link className={styles.headerLink} to="/">Vote</Link>
        <Link className={styles.headerLink} to="/rankings">Rankings</Link>
      </div>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
}