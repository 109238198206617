import {useEffect, useState} from "react";
import styles from './RankingPage.module.css';

export default function RankingPage() {
  // Load senators from the API using hooks
  const [rankings, setRankings] = useState(null);
  const [currentRanking, setCurrentRanking] = useState(null);
  const [voteCount, setVoteCount] = useState(0);
  const [userId, setUserId] = useState(() => {
    return localStorage.getItem('userId') || '';
  });

  useEffect(() => {
    if (!userId) {
      localStorage.setItem('userId', Math.random().toString(36).slice(2));
      setUserId(localStorage.getItem('userId'));
    }
  }, [userId]);

  useEffect(() => {
    fetch('/api/rankings')
      .then(res => res.json())
      .then(rankings => {
        setRankings(rankings)
      })
      .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    fetch(`/api/voteCount`)
      .then(res => res.json())
      .then(data => {
        setVoteCount(data.voteCount)
      });
  }, [voteCount]);

  useEffect(() => {
    if (rankings) {
      setCurrentRanking(rankings[0]);
    }
  }, [rankings]);

  const selectRanking = (e) => {
    const ranking = rankings.find(ranking => ranking.name === e.target.value);
    setCurrentRanking(ranking);
  }

  return (
    <div className={styles.page}>
      <h1 className={styles.title}>Charmers of the Chamber</h1>
      <h2 className={styles.subtitle}>Hotness Rankings 🥵</h2>
      {voteCount !== 0 && <div className={styles.voteCount}>Total vote count: {voteCount}</div>}
      {rankings && (
        <div className={styles.rankings}>
          <select className={styles.selection} onChange={selectRanking}>
            {rankings.map(ranking => (
              <option key={ranking.name} value={ranking.name}>{ranking.name} ({ranking.senators.length})</option>
            ))}
          </select>
          <div className={styles.ranking}>
            {currentRanking && currentRanking.senators.map(senator => (
              <div key={senator.id} className={styles.senator}>
                {/*Image with text displayed to the right*/}
                <img className={styles.profile} src={`${process.env.PUBLIC_URL}/senators/${senator.id}.jpg`} alt={senator.fullName} />
                {/*Senator name and wins*/}
                <div className={styles.senatorInfo}>
                  <div className={styles.senatorName}>{senator.fullName}</div>
                  <div className={styles.senatorDetail}>State: {senator.currentState}</div>
                  <div className={styles.senatorDetail}>Party: {senator.currentParty}</div>
                  <div className={styles.senatorDetail}>Hotness: {Number.parseFloat(senator.matchUpScore).toFixed(2)}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        )}
    </div>
  );
}