import {useEffect, useState} from "react";
import HotOrNot from "./components/HotOrNot";

export default function App() {
  // Load senators from the API using hooks
  const [allSenators, setSenators] = useState([]);
  const [contests, setContests] = useState(null);
  const [userId, setUserId] = useState(() => {
    return localStorage.getItem('userId') || '';
  });

  useEffect(() => {
    if (!userId) {
      localStorage.setItem('userId', Math.random().toString(36).slice(2));
      setUserId(localStorage.getItem('userId'));
    }
  }, []);

  useEffect(() => {
    fetch('/api/senators')
      .then(res => res.json())
      .then(allSenators => {
        setSenators(allSenators)
      })
      .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    fetch('/api/contests')
      .then(res => res.json())
      .then(contests => {
        setContests(contests)
      })
      .catch(err => console.error(err));
  }, []);

  return (
    <div className="app">
      {contests && <HotOrNot contests={contests} userId={userId} />}
    </div>
  );
}
