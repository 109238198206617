import {pick, postData} from "../clientUtils";
import {useEffect, useState} from "react";
import styles from './HotOrNot.module.css';

export default function HotOrNot({contests, userId}) {
  const [currentContest, setCurrentContest] = useState(contests[0]);
  const [currentSenators, setCurrentSenators] = useState([]);

  const pickNewSenators = (contest) => {
    setCurrentSenators(pick(contest.senators, 2));
  }

  if (currentSenators.length === 0) {
    pickNewSenators(currentContest);
  }

  const voteFor = (winner) => {
    const loser = currentSenators.find(senator => senator.id !== winner.id);
    postData('/api/vote', {
      winnerId: winner.id,
      loserId: loser.id,
      userId
    })
      .then(() => pickNewSenators(currentContest))
      .catch(err => console.error(err));
  }

  const selectContest = (e) => {
    const contest = contests.find(contest => contest.name === e.target.value);
    if (contest.senators.length < 2) {
      alert('Not enough senators in this contest yet');
      return;
    }
    pickNewSenators(contest);
    setCurrentContest(contest);
  }

  // Select left option on left arrow, right option on right arrow, and skip on space
  const handleKeyPress = (e) => {
    if (e.keyCode === 37) {
      voteFor(currentSenators[0]);
      e.preventDefault();
    } else if (e.keyCode === 39) {
      voteFor(currentSenators[1]);
      e.preventDefault();
    } else if (e.keyCode === 32) {
      pickNewSenators(currentContest);
      e.preventDefault();
    } else if (e.keyCode === 38) { // Up arrow
      const contestIndex = contests.indexOf(currentContest);
      if (contestIndex > 0) {
        selectContest({target: {value: contests[contestIndex - 1].name}});
      } else {
        selectContest({target: {value: contests[contests.length - 1].name}});
      }
      e.preventDefault();
    } else if (e.keyCode === 40) { // Down arrow
      const contestIndex = contests.indexOf(currentContest);
      if (contestIndex < contests.length - 1) {
        selectContest({target: {value: contests[contestIndex + 1].name}});
      } else {
        selectContest({target: {value: contests[0].name}});
      }
      e.preventDefault();
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress]);

  return (
    <div className={styles.hotOrNot}>
      <h1 className={styles.title}>Charmers of the Chamber</h1>
      <h2 className={styles.subtitle}>Who’s the hottest senator? 🥵<br/>Exercise your constitutional right by voting below 🗳️</h2>
      <select className={styles.contestSelect} onChange={selectContest}>
        {contests.map(contest => (
          <option key={contest.name} value={contest.name} selected={contest.name === currentContest.name}>{contest.name} ({contest.senators.length})</option>
        ))}
      </select>
      <div className={styles.senators}>
        {currentSenators.map(senator => (
          <div key={senator.id} className={styles.senator} onClick={() => voteFor(senator)}>
            <img className={styles.profile} src={`${process.env.PUBLIC_URL}/senators/${senator.id}.jpg`} alt={senator.fullName} />
          </div>
        ))}
      </div>
      <div>
        <button className={styles.skipButton} onClick={() => pickNewSenators(currentContest)}>Skip</button>
      </div>
	 <h3 className={styles.footnote}>Follow us on <a href="https://twitter.com/fillhimbusther">X (formerly known as Twitter)</a>! </h3>
	</div>
  );
}
